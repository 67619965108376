import type {
  Autopromo,
  MatchedShortCode,
  MatchedShortCodes,
} from "~/types/autopromo";
import { createMemo, DEV, For, lazy, Show } from "solid-js";
import { createStore } from "solid-js/store";
import { Dynamic } from "solid-js/web";
import type { Article, Journey, Landing } from "~/types/drupal_jsonapi";
import type { ProgramWrapper } from "~/utils/program_wrapper";

const debug = DEV && import.meta.env.VITE_COG_DEBUG_AUTOPROMOS == "1";

const PromoPinelMenu = lazy(() => import("./PromoPinelMenu"));
const PromoCnatMenuDwell = lazy(() => import("./PromoCnatMenuDwell"));
const PromoCnatMenuInvest = lazy(() => import("./PromoCnatMenuInvest"));
const PromoCnatMenuMixed = lazy(() => import("./PromoCnatMenuMixed"));
const PromoParkingMenu = lazy(() => import("./PromoParkingMenu"));
const PromoPTZ2024Menu = lazy(() => import("./PromoPTZ2024Menu"));

const SponsorshipMenu = lazy(() => import("./SponsorshipMenu"));
const MagazineTeasers = lazy(() => import("./MagazineTeasers"));

const YoutubeIframe = lazy(() => import("./YoutubeIframe"));
const Search = lazy(() => import("./PromoSearch"));
const PromoFaq = lazy(() => import("./PromoFaq"));
const PromoGuideInvest = lazy(() => import("./PromoGuideInvest"));
const PromoTools = lazy(() => import("./PromoTools"));
const PromoFormAdvisor = lazy(() => import("./PromoFormAdvisor"));
const PromoSimulatorLmnpBouvard1 = lazy(
  () => import("./PromoSimulatorLmnpBouvard1"),
);
const PromoSimulatorPtz2 = lazy(() => import("./PromoSimulatorPtz2"));
const PromoEscda = lazy(() => import("./PromoEscda"));
const PromoSponsorship = lazy(() => import("./PromoSponsorship"));
const PromoFinancialStudy = lazy(() => import("./PromoFinancialStudy"));

const PageCustomAccompagnementSurMesure = lazy(
  () => import("./PageCustomAccompagnementSurMesure"),
);
const PageCustomLogementsDifferents = lazy(
  () => import("./PageCustomLogementsDifferents"),
);
const PageCustomNouveauNeuf = lazy(() => import("./PageCustomNouveauNeuf"));
const PageCustomParcoursClient = lazy(
  () => import("./PageCustomParcoursClient"),
);
const PageCustomFinance = lazy(() => import("./PageCustomFinance"));
const PageCustomGestionLocative = lazy(
  () => import("./PageCustomGestionLocative"),
);
const PageCustomConciergerie = lazy(() => import("./PageCustomConciergerie"));
const PageCustomSolutionMeublee = lazy(
  () => import("./PageCustomSolutionMeublee"),
);
const PageCustomSolution10Immo = lazy(
  () => import("./PageCustomSolution10Immo"),
);

const Contents = lazy(() => import("./Contents"));
const Definitions = lazy(() => import("./Definitions"));

const PromoAreas = lazy(() => import("./PromoAreas"));
const PromoFormInPage = lazy(() => import("./PromoFormInPage"));
const PromoPTZ2024Home = lazy(() => import("./PromoPTZ2024Home"));

const PromoPinelHome = lazy(() => import("./PromoPinelHome"));

const PromoSimulatorPinelCard = lazy(() => import("./PromoSimulatorPinelCard"));
const PromoSimulatorPTZ2024Card = lazy(
  () => import("./PromoSimulatorPTZ2024Card"),
);
const PromoSimulatorLmnpCard = lazy(() => import("./PromoSimulatorLmnpCard"));

const autopromos: Autopromo[] = [
  {
    shortcode: "[sponsorship-menu]",
    isRegExp: false,
    component: SponsorshipMenu,
  },
  {
    shortcode: "[promo-pinel-menu]",
    isRegExp: false,
    component: PromoPinelMenu,
  },
  {
    shortcode: /\[youtube:(.*?)]/g,
    isRegExp: true,
    component: YoutubeIframe,
    propsPositions: { url: 0 },
  },
  {
    shortcode: /\[contents:(.*?)]/g,
    isRegExp: true,
    component: Contents,
    propsPositions: { nidsSeparatedByComma: 0 },
  },
  {
    shortcode: /\[definitions:(.*?)]/g,
    isRegExp: true,
    component: Definitions,
    propsPositions: { nidsSeparatedByComma: 0 },
  },
  {
    shortcode: "[magazine-teasers]",
    isRegExp: false,
    component: MagazineTeasers,
  },
  {
    shortcode: "[promo-cnat-menu-dwell]",
    isRegExp: false,
    component: PromoCnatMenuDwell,
  },
  {
    shortcode: "[promo-cnat-menu-invest]",
    isRegExp: false,
    component: PromoCnatMenuInvest,
  },
  {
    shortcode: "[promo-cnat-menu-mixed]",
    isRegExp: false,
    component: PromoCnatMenuMixed,
  },
  {
    shortcode: "[promo-parking-menu]",
    isRegExp: false,
    component: PromoParkingMenu,
  },
  {
    shortcode: "[promo-ptz-2024-menu]",
    isRegExp: false,
    component: PromoPTZ2024Menu,
  },
  {
    shortcode: "[search]",
    isRegExp: false,
    component: Search,
  },
  {
    shortcode: "[promo-faq]",
    isRegExp: false,
    component: PromoFaq,
  },
  {
    shortcode: "[promo-guide-invest]",
    isRegExp: false,
    component: PromoGuideInvest,
  },
  {
    shortcode: "[tools]",
    isRegExp: false,
    component: PromoTools,
  },
  {
    shortcode: "[promo-form_advisor]",
    isRegExp: false,
    component: PromoFormAdvisor,
  },
  {
    shortcode: "[promo-simulator_lmnp-bouvard-1]",
    isRegExp: false,
    component: PromoSimulatorLmnpBouvard1,
  },
  {
    shortcode: "[promo-simulator_ptz-2]",
    isRegExp: false,
    component: PromoSimulatorPtz2,
  },
  {
    shortcode: "[escda]",
    isRegExp: false,
    component: PromoEscda,
  },
  {
    shortcode: "[sponsorship]",
    isRegExp: false,
    component: PromoSponsorship,
  },
  {
    shortcode: "[promo-financial-study]",
    isRegExp: false,
    component: PromoFinancialStudy,
  },
  {
    shortcode: "[promo-areas]",
    isRegExp: false,
    component: PromoAreas,
  },
  {
    shortcode: "[promo-pinel-home]",
    isRegExp: false,
    component: PromoPinelHome,
  },
  {
    shortcode: "[promo-ptz-2024-home]",
    isRegExp: false,
    component: PromoPTZ2024Home,
  },
  {
    shortcode: "[page-custom-accompagnement-sur-mesure]",
    isRegExp: false,
    component: PageCustomAccompagnementSurMesure,
  },
  {
    shortcode: "[page-custom-logements-differents]",
    isRegExp: false,
    component: PageCustomLogementsDifferents,
  },
  {
    shortcode: "[page-custom-nouveau-neuf]",
    isRegExp: false,
    component: PageCustomNouveauNeuf,
  },
  {
    shortcode: "[page-custom-parcours-client]",
    isRegExp: false,
    component: PageCustomParcoursClient,
  },
  {
    shortcode: "[form-in-page]",
    isRegExp: false,
    component: PromoFormInPage,
    forwardEntity: true,
  },
  {
    shortcode: "[page-custom-finance]",
    isRegExp: false,
    component: PageCustomFinance,
  },
  {
    shortcode: "[page-custom-gestion-locative]",
    isRegExp: false,
    component: PageCustomGestionLocative,
  },
  {
    shortcode: "[page-custom-conciergerie]",
    isRegExp: false,
    component: PageCustomConciergerie,
  },
  {
    shortcode: "[page-custom-solution-meublee]",
    isRegExp: false,
    component: PageCustomSolutionMeublee,
  },
  {
    shortcode: "[page-custom-solution-10-immo]",
    isRegExp: false,
    component: PageCustomSolution10Immo,
  },
  {
    shortcode: "[promo-simulator-pinel-card]",
    isRegExp: false,
    component: PromoSimulatorPinelCard,
  },
  {
    shortcode: "[promo-simulator-ptz-2024-card]",
    isRegExp: false,
    component: PromoSimulatorPTZ2024Card,
  },
  {
    shortcode: "[promo-simulator-lmnp-card]",
    isRegExp: false,
    component: PromoSimulatorLmnpCard,
  },
] as const;

const debugShortcodeLabel = "🪬" as const;

/**
 * Returns input with known shortcodes converted to markup equivalent.
 *
 * Remove unwanted enclosing HTML tags due to WYSIWYG:
 *
 *  <p>[search]</p>       --> [search]
 *  <p> [search] </p>     --> [search]
 *  <div>[pinel-2]</div>  --> [pinel-2]
 *  <span>[lmnp_3]</span> --> [lmnp_3]
 */

export type AutopromosRendererProps = {
  text: string;
  wrapper?: ProgramWrapper;
  landing?: Landing;
  journey?: Journey;
  article?: Article;
  fromFieldName?: "field_ads" | "field_text" | "field_catchline";
};

export function AutopromosRenderer(props: AutopromosRendererProps) {
  const [matchedAutopromos, setMatchedAutopromos] =
    createStore<MatchedShortCodes>({});

  const processedText = createMemo(() => {
    let text = cleanShortcode(props.text);

    for (const autopromo of autopromos) {
      if (autopromo.isRegExp) {
        debug && console.group(debugShortcodeLabel, autopromo.shortcode);

        const matches = [...text.matchAll(autopromo.shortcode)];

        if (matches.length > 0) {
          for (const match of matches) {
            debug && console.debug("✅ Match", match);
            text = text.replace(match[0], `\n${match[0]}\n`);

            const params = match.slice(1);

            debug && console.debug("  params", params);

            let shortcodeProps: MatchedShortCode["props"] = {
              fromFieldName: props.fromFieldName,
            };

            if (autopromo.forwardEntity) {
              shortcodeProps = {
                ...shortcodeProps,
                wrapper: props.wrapper,
                landing: props.landing,
                journey: props.journey,
                article: props.article,
              };
            }

            if (autopromo.propsPositions) {
              Object.entries(autopromo.propsPositions).forEach(
                ([key, index]) => {
                  shortcodeProps = {
                    ...shortcodeProps,
                    [key]: params[index],
                  };
                },
              );
            }

            setMatchedAutopromos(match[0], {
              comp: autopromo.component,
              props: shortcodeProps,
            });
          }
        } else {
          debug && console.log("Not found");
        }
        debug && console.groupEnd();
      } else {
        debug && console.group(debugShortcodeLabel, autopromo.shortcode);

        if (text.includes(autopromo.shortcode)) {
          debug && console.log("✅ Found");
          text = text.replace(
            autopromo.shortcode,
            `\n${autopromo.shortcode}\n`,
          );

          let shortcodeProps: MatchedShortCode["props"] = {
            fromFieldName: props.fromFieldName,
          };

          if (autopromo.forwardEntity) {
            shortcodeProps = {
              ...shortcodeProps,
              wrapper: props.wrapper,
              landing: props.landing,
              article: props.article,
              journey: props.journey,
            };
          }

          setMatchedAutopromos(autopromo.shortcode, {
            comp: autopromo.component,
            props: shortcodeProps,
          });
        } else {
          debug && console.log("Not found");
        }

        debug && console.groupEnd();
      }
    }

    // Break text into lines to process each line separately so can use Dynamic
    return text.split("\n").filter((l) => l.trim() !== "");
  });

  const matchedKeys = () => Object.keys(matchedAutopromos);

  return (
    <For each={processedText()}>
      {(line) => (
        <Show
          when={matchedKeys().includes(line)}
          fallback={<div innerHTML={line} />}
        >
          <Dynamic
            component={matchedAutopromos[line].comp}
            {...matchedAutopromos[line].props}
          />
        </Show>
      )}
    </For>
  );
}

/**
 * Returns true if the text may contain shortcodes.
 *
 * This is a simple check to avoid running the shortcode parser when it's not
 * necessary. That should be very fast.
 *
 * @param text string, the text to check for shortcodes
 * @returns boolean, true if the text contains [ and ] characters
 */
export function mayHaveShortcode(text: string): boolean {
  const opening = text.indexOf("[");
  const closing = text.indexOf("]");
  // We don't want to match ]test[ or []
  return opening !== -1 && closing !== -1 && opening + 1 < closing;
}

export function hasMultipleShortcodes(text: string): boolean {
  const re = /\[[^\]]*\]/g;
  return ((text || "").match(re) || []).length > 1;
}

export function cleanShortcode(text: string): string {
  return text.replace(/(<[a-zA-Z]+>)(\s*)(\[.*?\])(\s*)(<\/[a-zA-Z]+>)/g, "$3");
}
